import { Col, Container, Row } from "react-bootstrap"
import Button from 'react-bootstrap/Button';

import { useState } from 'react';

import { PrivacyModal } from "./PrivacyModal";
import { LoginModal } from "./LoginModal";
import { useAuth } from "../../../../../auth/core/Auth";
import { useClientAuth } from "../../../_core/ClientAuthProvider";


const TermsForm = ({setAcceptedTerms, setNewOscFormData}) => {
    const { currentUser } = useAuth()
    const { showLogin, handleCloseLogin, handleShowLogin } = useClientAuth()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    setNewOscFormData(null)
    
    return (
        <Container>
            <Row>
                <Col>
                <p className="fs-3">A Connecting Food é uma empresa de impacto social. Nós trabalhamos conectando doadores de alimentos a Organizações Sociais que atendem pessoas em vulnerabilidade e fazendo a gestão do processo de doação.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                <p className="fs-3">Para conhecer mais sobre o nosso trabalho acesse nosso site: <a href="https://www.connectingfood.com"> www.connectingfood.com </a></p>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <p className="fs-3">Caso tenha interesse em entrar para o nosso banco de potenciais parceiras, necessitamos que preencha as informações referentes à Organização.</p>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <p className="fs-3"><b>Importante:</b> O preenchimento do formulário é uma primeira etapa e não garante que conseguiremos um parceiro doador e/ou que a Organização receberá doações imediatamente.</p>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <p className="fs-3">Para as Organizações que já possuem parceria com a Connecting Food, este formulário serve para atualização de dados cadastrais, para que nossa parceria se mantenha regular.</p>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <p className="fs-3">É muito importante que preencham as informações com muita atenção: é a partir dos dados que mostramos os impactos das doações e engajamos cada vez mais doadores.</p>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col xs={12} className="d-flex justify-content-center mt-3">
                    <p className="fs-3 fw-bolder">Ao continuar, você concorda com nossa <Button variant="link" onClick={handleShow} className="fs-3 fw-bolder">POLÍTICA DE PRIVACIDADE</Button></p>
                </Col>
                <Col xs={12} className="d-flex justify-content-center">
                    {!currentUser && (
                        <Button variant="success" onClick={() => handleShowLogin()}>
                            Fazer login
                        </Button>
                    )}
                    <Button variant="primary" onClick={() => setAcceptedTerms(true)} className="ms-1">
                        Avançar
                    </Button>
                </Col>
            </Row>
        <PrivacyModal showState={show} handleCloseState={handleClose} />
        <LoginModal showState={showLogin} handleCloseState={handleCloseLogin} />
        </Container>
    )
}

export {TermsForm}
import { Card } from "react-bootstrap"
import Image from 'react-bootstrap/Image';

import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import { KTSVG } from "../../../../../_metronic/helpers"

import { CreationForm } from "./form/CreationForm"
import { TermsForm } from "./form/TermsForm/TermsForm"
import { useNewOsc } from "../_core/NewOscProvider";
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from "react";

const NewOscFormWrapped = () => {

    const {acceptedTerms, setAcceptedTerms, setNewOscFormData, showInfoToast} = useNewOsc()
    useEffect(() => {
        if(showInfoToast){
            toast.info("Ja existe uma conta com esse cnpj, faça login para continuar.")
        }
    }, [showInfoToast])
    return (
        <>
            <Card>
                <Card.Header className="d-flex justify-content-center p-2" style={{backgroundColor: "rgba(179, 91, 39, 0.1)"}}>
                    <Image
                        src={toAbsoluteUrl("/media/avatars/connecting-food-logo-480x119.webp")}
                        fluid
                        style={{maxWidth: "70%"}}
                    />
                </Card.Header>
                <Card.Body style={{backgroundColor: "rgba(0, 0, 0, 0.1)"}}>
                    {acceptedTerms ? (
                        <CreationForm/>
                    ): (
                        <TermsForm setAcceptedTerms={setAcceptedTerms} setNewOscFormData={setNewOscFormData}/>
                    )}
                </Card.Body>
                <Card.Footer className="d-flex justify-content-center m-0 p-0" style={{backgroundColor: "rgba(253, 128, 62, 1)"}}>
                    <span className="fs-3 text-white" > <KTSVG path="/media/svg/misc/peace.svg" className="svg-icon-2"/> Fique tranquilo(a)! Estamos seguindo todas as medidas de proteção de dados conforme a LGPD (Lei nº 13.709/2018).</span>
                </Card.Footer>
            </Card>
            <ToastContainer/>
            
        </>

    )
}

export {NewOscFormWrapped}